// src/components/AboutUsSection.js
import React from 'react';
import aboutUsImage from '../assets/images/about-us-image.png';

function AboutUsSection() {
    return (
        <section className="AboutUs-section" id="about">
            <div className="AboutUs-content">
                <h2>Why Choose CyberZero AI?</h2>
                <p>
                    CyberZero AI harnesses the power of artificial intelligence to deliver cutting-edge cybersecurity solutions. Our intelligent systems proactively identify threats, adapt to new challenges, and provide robust protection for your digital assets.
                </p>
                <p>
                    By choosing CyberZero AI, you're partnering with a team that not only understands the complexities of the cyber world but also anticipates future risks. Our AI-driven approach ensures you stay one step ahead of cyber threats.
                </p>
                <p>
                    Join us in shaping a safer digital future where innovation meets security, and your protection is our top priority.
                </p>
            </div>
            <div className="AboutUs-image">
                <img src={aboutUsImage} alt="Why Choose CyberZero AI" />
            </div>
        </section>
    );
}

export default AboutUsSection;
