import React from 'react';
import './App.css';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import AboutUsSection from './components/AboutUsSection';
import ServicesSection from './components/ServicesSection';
import PlatformsSection from './components/PlatformsSection';
import TestimonialsSection from './components/TestimonialsSection';
import ContactSection from './components/ContactSection';
import Footer from './components/Footer';
import Analytics from './components/Analytics';
import { SEO } from './components/SEO';

function App() {
    return (
        <div className="App">
            <SEO
                title="CyberZero AI | Leading the Future of Cybersecurity"
                description="CyberZero AI offers advanced AI-driven cybersecurity solutions, white label services, tools, and educational courses. Protect your digital assets with our White Label Solutions, Tools Platform, and Safe Platform."
                keywords="CyberZero AI, cybersecurity, white label security, cybersecurity tools, cyber safety courses, developer utilities, security services, WebAssembly, WebGPU, WebLLM"
                author="CyberZero AI"
                image="/assets/og-image.png"
                url="https://cyberzero.ai/"
                canonical="https://cyberzero.ai/"
                structuredData={JSON.stringify({
                    '@context': 'https://schema.org',
                    '@type': 'Organization',
                    name: 'CyberZero AI',
                    url: 'https://cyberzero.ai/',
                    logo: 'https://cyberzero.ai/logo.png',
                    sameAs: [
                        'https://twitter.com/CyberZeroAI',
                        'https://linkedin.com/company/cyberzero-ai',
                    ],
                })}
            />
            <Analytics />
            <Header />
            <HeroSection />
            <AboutUsSection />
            <ServicesSection />
            <PlatformsSection />
            <TestimonialsSection />
            <ContactSection />
            <Footer />
        </div>
    );
}

export default App;
