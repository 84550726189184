import React from 'react';

function Footer() {
    return (
        <footer className="App-footer">
            <p>© {new Date().getFullYear()} CyberZero AI. All rights reserved.</p>
            <div className="Footer-links">
                <a href="mailto:info@cyberzero.ai">info@cyberzero.ai</a>
            </div>
        </footer>
    );
}

export default Footer;
