import React from 'react';
import { FaTools, FaBug, FaShieldAlt, FaLightbulb, FaCloud, FaLock } from 'react-icons/fa';

function ServicesSection() {
    return (
        <section className="Services-section" id="services">
            <h2>Our Services</h2>
            <div className="Services-grid">
                <div className="Service-card">
                    <FaTools className="Service-icon" />
                    <h3>Custom Utilities Development</h3>
                    <p>
                        We develop tailor-made utilities to meet your specific cybersecurity needs, ensuring seamless integration and optimal performance.
                    </p>
                </div>
                <div className="Service-card">
                    <FaBug className="Service-icon" />
                    <h3>Platform Testing</h3>
                    <p>
                        Comprehensive testing services to identify vulnerabilities and ensure the robustness of your platforms.
                    </p>
                </div>
                <div className="Service-card">
                    <FaShieldAlt className="Service-icon" />
                    <h3>Security Standards Review</h3>
                    <p>
                        Our experts assess your applications and cloud infrastructure to ensure compliance with the latest security standards.
                    </p>
                </div>
                <div className="Service-card">
                    <FaLightbulb className="Service-icon" />
                    <h3>Security Consultation</h3>
                    <p>
                        Strategic guidance and design solutions to enhance the security posture of your applications and cloud services.
                    </p>
                </div>
                <div className="Service-card">
                    <FaCloud className="Service-icon" />
                    <h3>Cloud Security Design</h3>
                    <p>
                        Designing secure cloud architectures that adhere to industry best practices and compliance requirements.
                    </p>
                </div>
                <div className="Service-card">
                    <FaLock className="Service-icon" />
                    <h3>Application Security</h3>
                    <p>
                        Reviewing and reinforcing security measures at the application level to protect your data and operations.
                    </p>
                </div>
            </div>
        </section>
    );
}

export default ServicesSection;
