import React from 'react';

function TestimonialsSection() {
    return (
        <section className="Testimonials-section" id="testimonials">
            <h2>What Our Clients Say</h2>
            <div className="Testimonials-grid">
                <div className="Testimonial-card">
                    <p>
                        "CyberZero AI's solutions have revolutionized our approach to cybersecurity. We confidently offer their white-labeled services under our brand, delivering top-tier protection to our clients."
                    </p>
                    {/*<h4>- Jane Doe, CTO at Tech Innovations</h4>*/}
                </div>
                <div className="Testimonial-card">
                    <p>
                        "The user-friendly interfaces and comprehensive tools make advanced cybersecurity accessible. Our clients trust us more than ever, thanks to CyberZero's white label options."
                    </p>
                    {/*<h4>- John Smith, Security Manager at SafeGuard Corp</h4>*/}
                </div>
                <div className="Testimonial-card">
                    <p>
                        "Their AI-driven applications saved us time and money. We launched a cybersecurity service under our brand in record time, and customer satisfaction is through the roof."
                    </p>
                    {/*<h4>- Sarah Lee, CEO at NextGen Solutions</h4>*/}
                </div>
            </div>
        </section>
    );
}

export default TestimonialsSection;
