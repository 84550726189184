import React from 'react';
import whiteLabelImage from '../assets/images/white-label.png'; // Add a suitable image
import toolsPlatformImage from '../assets/images/tools-platform.png';
import safePlatformImage from '../assets/images/safe-platform.png';

function PlatformsSection() {
    return (
        <section className="Platforms-section" id="platforms">
            <h2>Our Platforms</h2>

            {/* White Label Solutions Section */}
            <div className="Platform" id="white-label">
                <div className="Platform-content">
                    <h3>CyberZero White Label Solutions</h3>
                    <p>
                        White labeling empowers you to take our advanced cybersecurity software solutions and present them under your own brand. Instead of investing time and resources into building security tools from scratch, you can quickly deliver proven, enterprise-grade protection to your clients with your unique branding and identity.
                    </p>
                    <p>
                        <strong>Why Choose Our White Label Solutions?</strong>
                    </p>
                    <ul className="Platform-benefits">
                        <li>
                            <strong>Rapid Time-to-Market:</strong> Launch your own branded cybersecurity offerings quickly, without lengthy development cycles.
                        </li>
                        <li>
                            <strong>Cost-Effective Expansion:</strong> Avoid high R&D costs and leverage our existing, battle-tested solutions to grow your service portfolio.
                        </li>
                        <li>
                            <strong>Trusted Security Expertise:</strong> Benefit from CyberZero AI’s extensive cybersecurity expertise and technology stack.
                        </li>
                        <li>
                            <strong>Seamless Integration:</strong> Easily integrate our solutions into your current infrastructure for a smooth client experience.
                        </li>
                        <li>
                            <strong>Ongoing Support & Updates:</strong> Receive continuous updates, improvements, and expert support as new threats emerge.
                        </li>
                    </ul>
                    <p>
                        By choosing our white label solutions, you gain immediate access to top-tier cybersecurity capabilities—ready to be branded as your own—ensuring your clients receive cutting-edge protection without compromises.
                    </p>
                    <a href="#contact" className="Platform-button">Contact Us to Get Started</a>
                </div>
                <div className="Platform-image">
                    <img src={whiteLabelImage} alt="CyberZero White Label Solutions" />
                </div>
            </div>

            {/* Tools Platform */}
            <div className="Platform reverse">
                <div className="Platform-content">
                    <h3>CyberZero Tools Platform</h3>
                    <p>
                        Access a comprehensive suite of free cybersecurity and developer tools designed to enhance security, efficiency, and productivity. From password generators and encryption utilities to JSON formatters and IP address lookups, our Tools Platform is an invaluable resource for developers and security professionals.
                    </p>
                    <p>
                        The web-based accessibility ensures you can utilize these tools directly from your browser without any downloads or installations.
                    </p>
                    <a href="https://tools.cyberzero.ai" className="Platform-button">Explore Tools Platform</a>
                </div>
                <div className="Platform-image">
                    <img src={toolsPlatformImage} alt="CyberZero Tools Platform" />
                </div>
            </div>

            {/* Safe Platform */}
            <div className="Platform">
                <div className="Platform-content">
                    <h3>CyberZero Safe Platform</h3>
                    <p>
                        Join our 12-week online course "Cyber Safety Essentials for the Next Generation" to empower young learners aged 10-18 with essential cyber safety skills. The course covers safe browsing, deep fake safety, social media safety, and more.
                    </p>
                    <p>
                        Students gain access to exclusive tools and resources, including free VPN access, password manager, and antivirus software for one month.
                    </p>
                    <a href="https://safe.cyberzero.ai" className="Platform-button">Learn More About the Course</a>
                </div>
                <div className="Platform-image">
                    <img src={safePlatformImage} alt="CyberZero Safe Platform" />
                </div>
            </div>
        </section>
    );
}

export default PlatformsSection;
