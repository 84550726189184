import React from 'react';
import { Helmet } from 'react-helmet';

export const SEO = ({
                        title,
                        description,
                        keywords,
                        author,
                        image,
                        url,
                        ogType = 'website',
                        ogTitle,
                        ogDescription,
                        ogImage,
                        ogUrl,
                        twitterCard = 'summary_large_image',
                        twitterTitle,
                        twitterDescription,
                        twitterImage,
                        canonical,
                        structuredData,
                        meta = [],
                    }) => {
    const metaTags = [
        { name: 'description', content: description },
        { name: 'keywords', content: keywords },
        { name: 'author', content: author },
        { name: 'image', content: image },
        { name: 'url', content: url },
        // Open Graph Meta Tags
        { property: 'og:type', content: ogType },
        { property: 'og:title', content: ogTitle || title },
        { property: 'og:description', content: ogDescription || description },
        { property: 'og:image', content: ogImage || image },
        { property: 'og:url', content: ogUrl || url },
        // Twitter Card Meta Tags
        { name: 'twitter:card', content: twitterCard },
        { name: 'twitter:title', content: twitterTitle || title },
        { name: 'twitter:description', content: twitterDescription || description },
        { name: 'twitter:image', content: twitterImage || image },
        // Additional Meta Tags
        ...meta,
    ].filter((tag) => tag.content);

    return (
        <Helmet>
            <title>{title}</title>
            {metaTags.map((tag, index) => {
                if (tag.charset) {
                    return <meta key={index} charSet={tag.charset} />;
                }
                return (
                    <meta
                        key={index}
                        {...(tag.name ? { name: tag.name } : {})}
                        {...(tag.property ? { property: tag.property } : {})}
                        content={tag.content}
                    />
                );
            })}
            {canonical && <link rel="canonical" href={canonical} />}
            {structuredData && (
                <script type="application/ld+json">{structuredData}</script>
            )}
        </Helmet>
    );
};
