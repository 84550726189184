import React from 'react';

function ContactSection() {
    return (
        <section className="Contact-section" id="contact">
            <h2>Contact Us</h2>
            <p>
                Have questions or need more information about our white label solutions or other platforms? Reach out, and we'll be happy to assist you.
            </p>
            <a href="mailto:info@cyberzero.ai" className="Contact-button">Email Us</a>
        </section>
    );
}

export default ContactSection;
