// src/components/Analytics.js
import React from 'react';
import { Helmet } from 'react-helmet';

function Analytics() {
    return (
        <Helmet>
            {/* Google Analytics */}
            <script async src="https://www.googletagmanager.com/gtag/js?id=YOUR_GOOGLE_ANALYTICS_ID"></script>
            <script>
                {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', 'YOUR_GOOGLE_ANALYTICS_ID');
        `}
            </script>
        </Helmet>
    );
}

export default Analytics;
