import React from 'react';
import { Link } from 'react-scroll';
import logo from '../assets/logo.png';

function Header() {
    return (
        <header className="App-header">
            <img src={logo} className="App-logo" alt="CyberZero AI Logo" />
            <nav className="App-nav">
                <Link to="home" smooth={true} duration={500}>Home</Link>
                <Link to="about" smooth={true} duration={500}>About Us</Link>
                <Link to="services" smooth={true} duration={500}>Services</Link>
                <Link to="platforms" smooth={true} duration={500}>Platforms</Link>
                <Link to="testimonials" smooth={true} duration={500}>Testimonials</Link>
                <Link to="contact" smooth={true} duration={500}>Contact Us</Link>
            </nav>
        </header>
    );
}

export default Header;
