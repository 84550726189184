import React from 'react';
import heroImage from '../assets/images/hero-image.png';

function HeroSection() {
    return (
        <section className="Hero-section" id="home">
            <div className="Hero-text">
                <h1>Leading the Future of Cybersecurity with AI</h1>
                <p>
                    Experience next-generation cybersecurity solutions powered by artificial intelligence. Protect your digital world with CyberZero AI.
                </p>
                {/* Updated call-to-action to White Label Solutions */}
                <a href="#white-label" className="Hero-button">Discover Our White Label Solutions</a>
            </div>
            <div className="Hero-image">
                <img src={heroImage} alt="CyberZero AI - Advanced Cybersecurity Solutions" />
            </div>
        </section>
    );
}

export default HeroSection;
